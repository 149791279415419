import type { DTO } from '@/type-utils';
import Model from '../../Model';
import { MoneyModel } from '../../Money';
import type { IPlacedOrderTotals } from './IPlacedOrderTotals';

/**
 * Represents a collection of totals for an order.
 */
export class PlacedOrderTotalsModel
  extends Model<DTO<IPlacedOrderTotals>>
  implements IPlacedOrderTotals
{
  /** @inheritdoc */
  public readonly subtotal: MoneyModel;
  /** @inheritdoc */
  public readonly discount: MoneyModel;
  /** @inheritdoc */
  public readonly shipping: MoneyModel;
  /** @inheritdoc */
  public readonly tax: MoneyModel;
  /** @inheritdoc */
  public readonly total: MoneyModel;
  /** @inheritdoc */
  public readonly giftCard: MoneyModel;

  /** @inheritdoc */
  public constructor(dto: DTO<IPlacedOrderTotals>) {
    super(dto);

    this.subtotal = MoneyModel.fromAmount(dto.subtotal);
    this.discount = MoneyModel.fromAmount(dto.discount);
    this.shipping = MoneyModel.fromAmount(dto.shipping);
    this.tax = MoneyModel.fromAmount(dto.tax);
    this.total = MoneyModel.fromAmount(dto.total);
    this.giftCard = MoneyModel.fromAmount(dto.giftCard);
  }

  /** @inheritdoc */
  public toDTO(): DTO<IPlacedOrderTotals> {
    return {
      subtotal: this.subtotal.toDTO(),
      tax: this.tax.toDTO(),
      shipping: this.shipping.toDTO(),
      discount: this.discount.toDTO(),
      total: this.total.toDTO(),
      giftCard: this.giftCard.toDTO()
    };
  }
}
