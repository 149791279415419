import { DTO } from '@/type-utils';
import { Province } from '@/constructs/provinces/Province';

import { Country } from '../../isomorphic/I18NService';

import { IAWSAddress } from '../../serverless/integrations/AWS/order/data-structures/IAWSAddress';

import Model from '../Model';
import type { IAddress } from '.';

/** Provides a way to manipulate search results and facets. */
export default class AddressModel
  extends Model<DTO<IAddress>>
  implements IAddress
{
  /** @inheritdoc */
  public readonly addressLine1: string;

  /** @inheritdoc */
  public readonly addressLine2?: string;

  /** @inheritdoc */
  public readonly city: string;

  /** @inheritdoc */
  public readonly stateProvince: Province;

  /** @inheritdoc */
  public readonly country: Country;

  /** @inheritdoc */
  public readonly zipPostalCode: string;

  /** @inheritdoc */
  public readonly firstName: string;

  /** @inheritdoc */
  public readonly lastName: string;

  /** Builds a SearchResults model from a SearchResults representation.
   * @param address - A SearchResults representation.
   */
  public constructor(address: IAddress) {
    super(address);

    this.addressLine1 = address.addressLine1;
    this.addressLine2 = address.addressLine2;
    this.city = address.city;
    this.stateProvince = address.stateProvince;
    this.country = address.country;
    this.zipPostalCode = address.zipPostalCode;
    this.firstName = address.firstName;
    this.lastName = address.lastName;
  }

  /**
   * The place address is the address format used by the `AWSOrderEventService`.
   * Right now they are identical, but this should adapt with changes to either side.
   * @returns The address in the order event format.
   */
  public get placeAddress(): IAWSAddress {
    return this.toDTO();
  }

  /** @inheritDoc */
  public toDTO(): DTO<IAddress> {
    return {
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      stateProvince: this.stateProvince,
      country: this.country,
      zipPostalCode: this.zipPostalCode,
      firstName: this.firstName,
      lastName: this.lastName
    };
  }
}
