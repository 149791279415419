import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { PaymentInterruptedError } from './PaymentInterruptedError';

export const { PaymentCanceledError } = errorFactory(
  'PaymentCanceledError',
  PaymentInterruptedError
);

/** An error to use when a payment request is canceled by the user. */
export type PaymentCanceledError = InstanceTypeOf<typeof PaymentCanceledError>;
