'use client';

import { createContext } from 'react';
import { INotificationController } from './useNotificationController';

/**
 * This context will contain the {@link INotificationController} object
 * and provide most of the component tree access to it .
 */
const NotificationContext = createContext<INotificationController>({
  // Empty queue.
  notificationQueue: [],

  // Throw an error if this is called in its default state.
  queueNotification: () => {
    throw new Error('Called queueNotification on default context.');
  },

  // Throw an error if this is called in its default state.
  deleteNotification: () => {
    throw new Error('Called deleteNotification on default context.');
  }
});
NotificationContext.displayName = 'NotificationContext';

export default NotificationContext;
