import Service from '@/services/Service';
import type { CartModel } from '@/services/models/Cart';
import type { PlacedOrderModel } from '@/services/models/Order';
import type { ApplePayPaymentBundle } from '.';
import { ApplePayService } from './ApplePayService';
import type { OnPaymentAuthorizedCallback } from './IPaymentMethodService';

/**
 * Service to handle payment Request using third party APIs like Apple Pay and Google Pay.
 */
export class PaymentRequestService extends Service {
  private inRequest: boolean = false;

  /**
   * Initiates a payment request using Apple Pay.
   * @param cart - The cart to be ordered.
   * @param onPaymentAuthorized - A callback to be called when the
   * payment is authorized. This must return a `PlacedOrderModel`.
   * @returns A promise that resolves when the order is placed and
   * the payment sheet is dismissed.
   */
  public async requestApplePayPayment(
    cart: CartModel,
    onPaymentAuthorized: OnPaymentAuthorizedCallback<ApplePayPaymentBundle>
  ): Promise<PlacedOrderModel> {
    if (this.inRequest) {
      throw new Error('Payment request already in progress.');
    }

    this.inRequest = true;

    return new ApplePayService()
      .runSession(cart, onPaymentAuthorized)
      .finally(() => {
        this.inRequest = false;
      });
  }
}

export default new PaymentRequestService();
