import { DTO } from '@/type-utils';
import { IEncryptedPaymentData, PaymentMethodName } from '..';
import Model from '../../Model';
import IOrderPaymentInfo from './IOrderPaymentInfo';

/**
 * Represents the credit card payment information for an order.
 */
export default class OrderPaymentInfoModel
  extends Model<DTO<IOrderPaymentInfo>>
  implements IOrderPaymentInfo
{
  /** @inheritdoc */
  public readonly paymentMethod: PaymentMethodName;
  /** @inheritdoc */
  public readonly firstName: string;
  /** @inheritdoc */
  public readonly lastName: string;
  /** @inheritdoc */
  public readonly expMonth: string;
  /** @inheritdoc */
  public readonly expYear: string;
  /** @inheritdoc */
  public readonly encryptedPayment: IEncryptedPaymentData;

  /**
   * Creates a new order shipping info model.
   * @param data - The data for the model.
   */
  public constructor(data: DTO<IOrderPaymentInfo> | IOrderPaymentInfo) {
    super(data);

    this.paymentMethod = data.paymentMethod;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.expMonth = data.expMonth;
    this.expYear = data.expYear;
    this.encryptedPayment = data.encryptedPayment;
  }

  /** @inheritdoc */
  public override toDTO(): DTO<IOrderPaymentInfo> {
    const dto: DTO<IOrderPaymentInfo> = {
      paymentMethod: this.paymentMethod,
      firstName: this.firstName,
      lastName: this.lastName,
      expMonth: this.expMonth,
      expYear: this.expYear,
      encryptedPayment: this.encryptedPayment
    };

    return dto;
  }
}
