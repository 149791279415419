import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { PaymentInterruptedError } from './PaymentInterruptedError';

export const { PaymentTimeoutError } = errorFactory(
  'PaymentTimeoutError',
  PaymentInterruptedError
);

/** An error to use when a payment request times out before completion. */
export type PaymentTimeoutError = InstanceTypeOf<typeof PaymentTimeoutError>;
