import type { IBrowserData } from '@/services/models/Order';
import { evaluateBreakpoints } from '../hooks/useBreakpoints';

/**
 * A util for getting browser data collected during the place order process.
 * @returns The browser data object.
 */
export function getBrowserData(): IBrowserData {
  const { userAgent } = window.navigator;
  const { isDesktop } = evaluateBreakpoints(window.innerWidth);

  return {
    userAgent,
    shopperDevice: isDesktop ? 'desktop' : 'mobile'
  };
}
