import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { ForbiddenActionError } = errorFactory('ForbiddenActionError', {
  mapsToHTTPError: 'ForbiddenError'
} as IHTTPErrorMetaData);

/**
 * Error to use when expressing an action was not permissible. Useful for
 * highlighting actions that may indicate incorrect or abusive usage of an API.
 */
export type ForbiddenActionError = InstanceTypeOf<typeof ForbiddenActionError>;
