import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { ServerApplePayValidateMerchantService } from '@/services/serverless/ServerApplePayValidateMerchantService';
import Service from '@/services/Service';
import axios, { AxiosInstance } from 'axios';
import type { OpaqueMerchantSession } from '../../ServerApplePayValidateMerchantService/ServerApplePayValidateMerchantService';

/**
 * An isomorphic micro-service for validating our merchant identity with the Apple Pay server.
 * This is required to initialize the Apple Pay payment session.
 */
class ApplePayValidateMerchantService extends Service {
  private client: AxiosInstance;

  /** @inheritdoc */
  public constructor() {
    super();

    this.client = axios.create({
      baseURL: '/api/apple-pay'
    });
  }

  /**
   * Validates our merchant identity from the given domain with the Apple Pay server.
   * This is required to initialize the Apple Pay payment session. For security reasons,
   * the validation with the Apple Pay server must be done on the server side.
   *
   * @param initiativeContext - The domain/hostname of the site requesting the session.
   * @returns - An opaque merchant session object used to initialize the Apple Pay payment session.
   * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/requesting_an_apple_pay_payment_session#overview Requesting an Apple Pay Payment Session}
   */
  public async validateWebSession(
    initiativeContext: string
  ): Promise<OpaqueMerchantSession> {
    if ((typeof window === "undefined")) {
      return ServerApplePayValidateMerchantService.validateWebSession(
        initiativeContext
      );
    }

    const { data } = await this.client.post<OpaqueMerchantSession>(
      'payment-session',
      { initiativeContext }
    );

    return data;
  }
}

export default new ApplePayValidateMerchantService();
