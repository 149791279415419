'use client';

import { useContext } from 'react';

import { ErrorAlertContext } from '../components/core-ui/ErrorAlert/ErrorAlertContext';
import type { IErrorAlertContext } from '../components/core-ui/ErrorAlert/ErrorAlertContext/IErrorAlertContext';

/**
 * `useErrorAlert` retrieves the context for managing the nearest error alert.
 * @returns The error alert context.
 * @throws An {@link TypeError} if the context is null.
 */
export const useErrorAlert = (): IErrorAlertContext => {
  const context = useContext(ErrorAlertContext);

  if (!context) {
    throw new TypeError(
      'ErrorAlertContext is null. useErrorAlert hook must be used inside an ErrorAlertContext.Provider.'
    );
  }

  return context;
};
