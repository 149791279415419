import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { PaymentInterruptedError } = errorFactory(
  'PaymentInterruptedError'
);

/**
 * A generic error to use when a payment request is interrupted, e.g. it was
 * canceled by the user, it was aborted due to an error or timeout, etc..
 *
 * **Note:** Due to nature of some payment methods, this error might not be
 * thrown in _all_ cases where a payment is interrupted.
 */
export type PaymentInterruptedError = InstanceTypeOf<
  typeof PaymentInterruptedError
>;
