/* eslint-disable no-void -- this file is effectively client-side only */

import type { USProvince } from '@/constructs/provinces/US/USProvince';
import type { IAddress } from '@/services/models/Address';
import type { CartModel } from '@/services/models/Cart';
import { MoneyModel } from '@/services/models/Money';
import type { PlacedOrderModel } from '@/services/models/Order';
import type { ShippingMethodModel } from '@/services/models/ShippingMethod';
import { ApplePayValidateMerchantService } from '@/services/serverless/integrations/ApplePayValidateMerchantService';
import { isFullNameDOMSCompatible } from '@/services/utils/order-validations/isFullNameDOMSCompatible';
import { safeValidatePhoneNumber } from '@/services/utils/phone-utils';
import { difference, pickMap } from '@/utils/array-utils';
import { withPromiseResolvers } from '@/utils/async-utils';
import { invokeImmediately } from '@/utils/function-utils';
import { isNullOrEmpty, isNullOrZero } from '@/utils/null-utils';
import { isValidEmail } from '@/utils/string-utils';
import type { ApplePayPaymentBundle } from '.';
import { Config } from '../ConfigurationService';
import ConfigurationService from '../ConfigurationService/ConfigurationService';
import { EnvironmentService } from '../EnvironmentService';
import I18NService, { msg, msgf, type Country } from '../I18NService';
import LoggerService from '../LoggerService';
import PromotionsService, {
  CouponExpiredError,
  RejectedCouponError
} from '../PromotionsService';
import {
  PaymentAbortedError,
  PaymentCanceledError,
  PaymentTimeoutError
} from './errors';
import {
  IPaymentMethodService,
  OnPaymentAuthorizedCallback,
  type IExpressPayment
} from './IPaymentMethodService';
import { order_orderSummary_subTotal } from "@/lang/__generated__/ahnu/order_orderSummary_subTotal";
import { order_orderSummary_discount } from "@/lang/__generated__/ahnu/order_orderSummary_discount";
import { order_orderSummary_shipping } from "@/lang/__generated__/ahnu/order_orderSummary_shipping";
import { order_orderSummary_tax } from "@/lang/__generated__/ahnu/order_orderSummary_tax";
import { order_orderSummary_giftCard } from "@/lang/__generated__/ahnu/order_orderSummary_giftCard";
import { checkout_shipping_errors_cannotShipInternationally } from "@/lang/__generated__/ahnu/checkout_shipping_errors_cannotShipInternationally";
import { checkout_shipping_errors_zipCode } from "@/lang/__generated__/ahnu/checkout_shipping_errors_zipCode";
import { cart_couponCodes_rejection_generic } from "@/lang/__generated__/ahnu/cart_couponCodes_rejection_generic";
import { checkout_shipping_errors_nameTooLong } from "@/lang/__generated__/ahnu/checkout_shipping_errors_nameTooLong";
import { forms_field_email } from "@/lang/__generated__/ahnu/forms_field_email";
import { forms_field_phone } from "@/lang/__generated__/ahnu/forms_field_phone";
import { forms_error_validEmail } from "@/lang/__generated__/ahnu/forms_error_validEmail";
import { forms_error_validPhoneNumber } from "@/lang/__generated__/ahnu/forms_error_validPhoneNumber";

/**
 * Service to handle Apple Pay payment requests.
 * This service is used to process the payment request and retrieve a valid token.
 */
export class ApplePayService implements IPaymentMethodService {
  /**
   * The configuration for Apple Pay.
   * @returns The configuration for Apple Pay.
   */
  public get config(): Config<'applePay'> {
    return ConfigurationService.getConfig('applePay');
  }

  /**
   * Transforms the shipping contact information to an address.
   *
   * **NOTE**:
   *
   * Before the payment is authorized by the user, ApplePay only provides
   * a subset of the shipping contact information, for privacy reasons.
   * However, it should be enough to determine the new applicable shipping
   * methods, and to calculate the new total. Only once the payment is
   * authorized does ApplePay provide the full shipping contact information.
   *
   * This is why we only return a partial address here.
   *
   * @param shippingContact - The shipping contact information.
   * @returns The shipping contact information as an address.
   */
  private transformShippingContact(
    shippingContact: ApplePayJS.ApplePayPaymentContact
  ): Partial<IAddress> {
    return {
      firstName: shippingContact.givenName,
      lastName: shippingContact.familyName,
      addressLine1: shippingContact.addressLines?.[0],
      addressLine2: shippingContact.addressLines?.[1],
      city: shippingContact.locality,
      stateProvince: shippingContact.administrativeArea as USProvince,
      zipPostalCode: shippingContact.postalCode,
      country: shippingContact.countryCode as Country
    };
  }

  /**
   * Transforms the cart totals to a list of Apple Pay line items.
   * @param cart - The cart to transform the totals from.
   * @param isPending - Whether the payment is pending or not.
   * @returns The cart totals as line items.
   */
  private transformTotalToLineItem(
    { total, giftCardAmount }: CartModel,
    isPending: boolean = false
  ): ApplePayJS.ApplePayLineItem {
    return {
      type: isPending ? 'pending' : 'final',
      /**
       * For the total, the label should be a business name, preferably
       * one that matches the charge on the bank statement.
       */
      label: this.config.getSetting('displayName').value,
      amount: total.value.copy().subtractAmount(giftCardAmount).toFixed().amount
    };
  }

  /**
   * Extracts the cart totals as line items.
   * @param cart - The cart to extract the totals from.
   * @returns The cart totals as line items.
   */
  private extractTotalsAsLineItems(
    cart: CartModel
  ): Array<ApplePayJS.ApplePayLineItem> {
    const result: Array<ApplePayJS.ApplePayLineItem> = [];

    const { selectedShippingMethod } = cart;
    const cartSubtotal = cart.subtotal.copy();
    const cartDiscount = cart.discount.value.copy();
    const cartTax = cart.tax.value.copy();
    const cartGiftCardAmount = cart.giftCardAmount.copy();

    const zeroMoney = MoneyModel.fromAmount(0, cartDiscount.currency);

    /** The raw cost of the selected shipping method. */
    const rawShippingCost =
      selectedShippingMethod?.shippingCost.copy() ?? zeroMoney;
    /** The effective shipping cost (after applying shipping discounts). */
    const effectiveShippingCost = cart.shippingCost.value.copy();
    /**
     * The difference between the raw shipping cost
     * and the effective shipping cost.
     *
     * **NOTE**: while it should never happen that the effective shipping cost
     * is greater than the raw shipping cost, it's better to not bound the
     * value to zero here, or else the displayed line items may not sum
     * up to the total, which can dissuade customers.
     */
    const shippingCostDiff = MoneyModel.subtract(
      rawShippingCost,
      effectiveShippingCost
    );

    /**
     * Add the difference to the discounted amount.
     *
     * **NOTE**: this is a workaround for the fact that shipping methods don't
     * track their promotions, nor calculate them before being selected. So
     * instead of showing the effective cost on the shipping method selection
     * dropdown, we treat the difference like a discount.
     */
    cartDiscount.addAmount(shippingCostDiff);

    // Subtotal
    result.push({
      type: 'final',
      label: msg(order_orderSummary_subTotal),
      amount: cartSubtotal.toFixed().amount
    });

    // Discount
    if (cartDiscount.isPositive) {
      result.push({
        type: 'final',
        label: msg(order_orderSummary_discount),
        amount: cartDiscount.negate().toFixed().amount
      });
    }

    // Shipping
    result.push({
      type: 'final',
      label: msg(order_orderSummary_shipping),
      // show the raw amount since the difference is
      // already accounted for in the discount
      amount: rawShippingCost.toFixed().amount
    });

    // Tax
    result.push({
      type: 'final',
      label: msg(order_orderSummary_tax),
      amount: cartTax.toFixed().amount
    });

    // Gift Card
    if (cartGiftCardAmount.isPositive) {
      result.push({
        type: 'final',
        label: msg(order_orderSummary_giftCard),
        amount: cartGiftCardAmount.negate().toFixed().amount
      });
    }

    return result;
  }

  /**
   * Transforms a shipping method model to an Apple Pay shipping method.
   * @param shippingMethod - The shipping method model to transform.
   * @returns An Apple Pay shipping method.
   */
  private transformShippingMethod(
    shippingMethod: ShippingMethodModel
  ): ApplePayJS.ApplePayShippingMethod {
    const {
      uid,
      description,
      name,
      shippingCost,
      earliestDeliveryDays,
      latestDeliveryDays
    } = shippingMethod;

    return {
      identifier: uid,
      detail: description,
      label: name,
      amount: shippingCost.toFixed().amount,
      dateComponentsRange:
        !isNullOrZero(earliestDeliveryDays) && !isNullOrZero(latestDeliveryDays)
          ? {
              startDateComponents: {
                days: earliestDeliveryDays,
                hours: 0,
                months: 0,
                years: 0
              },
              endDateComponents: {
                days: latestDeliveryDays,
                hours: 0,
                months: 0,
                years: 0
              }
            }
          : undefined
    };
  }

  /**
   * Runs an Apple Pay session until an order is placed.
   * @param cart - The cart to be ordered.
   * @param onPaymentAuthorized - Callback to be called when the payment is authorized.
   * @returns A promise that resolves when the order is placed and
   * the payment sheet is dismissed.
   */
  public async runSession(
    cart: CartModel,
    onPaymentAuthorized: OnPaymentAuthorizedCallback<ApplePayPaymentBundle>
  ): Promise<PlacedOrderModel> {
    const isEnabled = this.config.getSetting('enabled').value;
    if (!isEnabled) {
      throw new Error('Apple Pay is not enabled.');
    }

    if (!window.ApplePaySession) {
      throw new Error('Your browser does not support Apple Pay on the web.');
    }

    const { promise, resolve, reject } =
      withPromiseResolvers<PlacedOrderModel>();

    const applePayVersion = this.config.getSetting('version').value;
    const supportedNetworks = this.config.getSetting('supportedNetworks')
      .value as unknown as Array<string>; // config types are very broken

    const initialShippingMethodID = cart.selectedShippingMethod?.id;

    const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
      supportedNetworks,
      currencyCode: I18NService.currency,
      countryCode: I18NService.currentLocale.country,
      merchantCapabilities: ['supports3DS'],
      lineItems: this.extractTotalsAsLineItems(cart),
      total: this.transformTotalToLineItem(cart, true),
      requiredShippingContactFields: [
        'email',
        'postalAddress',
        'name',
        'phone'
      ],
      /** `postalAddress` is enough to give us the billing address, including the name. */
      requiredBillingContactFields: ['postalAddress'],
      /**
       * Apple Pay for some reason only supports one coupon code at a time, while we have the
       * ability to support multiple coupon codes. As a result, if a user wants to apply a
       * coupon code, they must do it through our UI before initiating the Apple Pay session.
       */
      supportsCouponCode: false,
      /**
       * Don't initially show the shipping methods, because we need to wait for a
       * shipping contact to be selected before retrieving the applicable shipping methods.
       * Otherwise, the customer will see stale shipping methods.
       */
      shippingMethods: undefined
    };

    const session = new window.ApplePaySession(applePayVersion, paymentRequest);

    // #region Shipping Method Handling
    session.onshippingmethodselected = (event) => {
      const shippingMethodID = event.shippingMethod.identifier;

      void invokeImmediately(async () => {
        try {
          await cart.selectShippingMethod(shippingMethodID);
          session.completeShippingMethodSelection({
            newTotal: this.transformTotalToLineItem(cart),
            newLineItems: this.extractTotalsAsLineItems(cart)
          });
        } catch (cause) {
          // `completeShippingMethodSelection` does not support reporting errors.
          // This is because if the shipping method selection fails, then the
          // session must have been in some invalid state, so we should abort it.
          session.abort();
          reject(
            new PaymentAbortedError('Shipping method selection failed.', {
              cause
            })
          );
        }
      });
    };
    // #endregion

    // #region Shipping Contact Handling
    let isFirstShippingContactSelection = true;
    session.onshippingcontactselected = (event) => {
      const { shippingContact } = event;
      const oldApplicableMethods = cart.applicableShippingMethods.value;

      // Business Requirement: fail if the shipping address is outside the current country/locale.
      if (shippingContact.countryCode !== I18NService.currentLocale.country) {
        session.completeShippingContactSelection({
          errors: [
            new ApplePayError(
              'shippingContactInvalid',
              'countryCode',
              msg(checkout_shipping_errors_cannotShipInternationally)
            )
          ],
          newTotal: this.transformTotalToLineItem(cart),
          newLineItems: this.extractTotalsAsLineItems(cart),
          newShippingMethods: []
        });

        return;
      }

      /**
       * **NOTE**: Before payment authorization, ApplePay only provides a subset of the
       * shipping contact information, for privacy reasons. This should be enough to
       * determine the new shipping methods and calculate taxes, but not enough to
       * fully validate the information. Specifically, the only information provided is:
       * - 'countryCode' (country in ISO 3166-1)
       * - 'administrativeArea' (state/province)
       * - 'locality' (city)
       * - 'subLocality' (district/neighborhood/borough)
       * - 'subAdministrativeArea' (county)
       * - 'postalCode' (zip/postal code).
       *
       * As a result, we must skip any other validation until the payment is authorized.
       */

      void invokeImmediately(async () => {
        try {
          const address = this.transformShippingContact(shippingContact);
          // pretend its a full address just so we can recalculate shipping methods and tax.
          await cart.setShipToAddress(address as IAddress);
        } catch {
          session.completeShippingContactSelection({
            errors: [
              new ApplePayError(
                'shippingContactInvalid',
                'postalCode',
                msg(checkout_shipping_errors_zipCode)
              )
            ],
            newTotal: this.transformTotalToLineItem(cart),
            newLineItems: this.extractTotalsAsLineItems(cart),
            newShippingMethods: []
          });

          return;
        }

        const newApplicableMethods = [...cart.applicableShippingMethods.value];

        /**
         * By default, the Apple Pay modal will preselect the **first** shipping method.
         * So sort the methods such that the {@link initialShippingMethodID initial shipping method} is first, and the
         * rest are sorted by increasing cost.
         */
        newApplicableMethods.sort((methodA, methodB) => {
          if (methodA.id === initialShippingMethodID) return -1;
          if (methodB.id === initialShippingMethodID) return 1;
          return MoneyModel.compare(methodA.shippingCost, methodB.shippingCost);
        });

        const oldApplicableMethodIds = pickMap(oldApplicableMethods, 'uid');
        const newApplicableMethodIds = pickMap(newApplicableMethods, 'uid');

        const [oldIdsDiff, newIdsDiff] = difference(
          oldApplicableMethodIds,
          newApplicableMethodIds
        );

        /**
         * Represents if the shipping methods have changed.
         *
         * This is used to prevent the session from deselecting
         * the previously selected shipping method.
         */
        const hasShippingMethodChanges = !(
          // If both diffs are empty, that means the shipping methods have not changed.
          (oldIdsDiff.length === 0 && newIdsDiff.length === 0)
        );

        session.completeShippingContactSelection({
          newTotal: this.transformTotalToLineItem(cart),
          newLineItems: this.extractTotalsAsLineItems(cart),
          newShippingMethods:
            // since we don't show stale shipping methods, always render the "new"
            // ones if it's the first time in the session's lifetime
            hasShippingMethodChanges || isFirstShippingContactSelection
              ? newApplicableMethods.map((shippingMethod) =>
                  this.transformShippingMethod(shippingMethod)
                )
              : undefined
        });

        isFirstShippingContactSelection = false;
      });
    };
    // #endregion

    // #region Coupon Code Handling
    session.oncouponcodechanged = (event) => {
      const { couponCode } = event;

      void invokeImmediately(async () => {
        try {
          await cart.applyCoupon(couponCode);
          await cart.total; // ensure the total is updated

          session.completeCouponCodeChange({
            newTotal: this.transformTotalToLineItem(cart),
            newLineItems: this.extractTotalsAsLineItems(cart)
          });
        } catch (err) {
          let errors: Array<ApplePayJS.ApplePayError> | undefined;

          if (err instanceof RejectedCouponError) {
            errors = [
              new ApplePayError(
                err instanceof CouponExpiredError
                  ? 'couponCodeExpired'
                  : 'couponCodeInvalid',
                undefined,
                PromotionsService.getRejectionReasonMessage(err)
              )
            ];
          } else {
            errors = [
              new ApplePayError(
                'unknown',
                undefined,
                msg(cart_couponCodes_rejection_generic)
              )
            ];
          }

          session.completeCouponCodeChange({
            errors,
            newTotal: this.transformTotalToLineItem(cart),
            newLineItems: this.extractTotalsAsLineItems(cart)
          });
        }
      });
    };
    // #endregion

    // #region Merchant Validation Handling
    session.onvalidatemerchant = () => {
      /**
       * As soon as the system displays the payment sheet, Apple Pay calls this
       * event handler to verify that the request is coming from a valid merchant.
       *
       * Although the event object provides a `validationURL` property which represents
       * the Apple Pay server endpoint to validate the merchant, this is being deprecated
       * in favor of the static Payment Session endpoint. This also ensures that a customer
       * can't maliciously send a different URL to spoof the Apple Pay server.
       * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/providing_merchant_validation - Providing Merchant Validation}
       */

      void invokeImmediately(async () => {
        try {
          const merchantSession =
            await ApplePayValidateMerchantService.validateWebSession(
              EnvironmentService.url.hostname
            );

          session.completeMerchantValidation(merchantSession);
        } catch (error) {
          // `completeMerchantValidation` does not support reporting errors.
          // This is because merchant validation is a critical step, and so
          // if it fails we must abort the session.
          session.abort();
          reject(
            new PaymentAbortedError('Merchant validation failed.', {
              cause: error
            })
          );
        }
      });
    };
    // #endregion

    // #region Payment Authorization Handling
    const paymentAuthorizationMainHandler = async (
      payment: ApplePayJS.ApplePayPayment
    ): Promise<void> => {
      const { shippingContact, billingContact, token } = payment;

      const errors: Array<ApplePayJS.ApplePayError> = [];

      if (!shippingContact) {
        errors.push(
          new ApplePayError(
            'shippingContactInvalid',
            'postalCode',
            'Missing shipping contact information.'
          )
        );
      }

      if (!billingContact) {
        errors.push(
          new ApplePayError(
            'billingContactInvalid',
            'postalCode',
            'Missing billing contact information.'
          )
        );
      }

      if (errors.length > 0) {
        session.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors
        });
        // Do not reject the promise here to allow the customer to retry the payment.
        return;
      }

      const requiredShippingContact = shippingContact!;
      const requiredBillingContact = billingContact!;

      const shippingEmail = requiredShippingContact.emailAddress?.trim();
      const phoneNumber = requiredShippingContact.phoneNumber?.trim();
      const countryCode = requiredShippingContact.countryCode as Country;

      const shippingAddress = this.transformShippingContact(
        requiredShippingContact
      ) as IAddress;
      const billingAddress = this.transformShippingContact(
        requiredBillingContact
      ) as IAddress;

      if (!shippingAddress.firstName?.trim()) {
        errors.push(
          new ApplePayError(
            'shippingContactInvalid',
            'name',
            'Missing first name in shipping contact.'
          )
        );
      }

      if (!shippingAddress.lastName?.trim()) {
        errors.push(
          new ApplePayError(
            'shippingContactInvalid',
            'name',
            'Missing last name in shipping contact.'
          )
        );
      }

      if (errors.length > 0) {
        session.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors
        });
        // Do not reject the promise here to allow the customer to retry the payment.
        return;
      }

      if (
        !isFullNameDOMSCompatible([
          shippingAddress.firstName,
          shippingAddress.lastName
        ])
      ) {
        errors.push(
          new ApplePayError(
            'shippingContactInvalid',
            'name',
            msg(checkout_shipping_errors_nameTooLong)
          )
        );
      }

      if (isNullOrEmpty(shippingEmail) || !isValidEmail(shippingEmail)) {
        errors.push(
          new ApplePayError(
            'shippingContactInvalid',
            'emailAddress',
            msgf(forms_error_validEmail, {
              name: msg(forms_field_email).toLowerCase()
            })
          )
        );
      }

      if (
        isNullOrEmpty(phoneNumber) ||
        !safeValidatePhoneNumber(phoneNumber, countryCode)
      ) {
        errors.push(
          new ApplePayError(
            'shippingContactInvalid',
            'phoneNumber',
            msgf(forms_error_validPhoneNumber, {
              name: msg(forms_field_phone).toLowerCase()
            })
          )
        );
      }

      if (!billingAddress.firstName?.trim()) {
        errors.push(
          new ApplePayError(
            'billingContactInvalid',
            'name',
            'Missing first name in billing contact.'
          )
        );
      }

      if (!billingAddress.lastName?.trim()) {
        errors.push(
          new ApplePayError(
            'billingContactInvalid',
            'name',
            'Missing last name in billing contact.'
          )
        );
      }

      if (errors.length > 0) {
        session.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors
        });
        // Do not reject the promise here to allow the customer to retry the payment.
        return;
      }

      /**
       * We need to sync the cart's shipping address with the
       * full address info returned upon authorization.
       *
       * TODO: find a synchronous way to do this since this runs
       * revalidation again, which is unnecessary.
       */
      await cart.setShipToAddress(shippingAddress);

      const expressPayment: IExpressPayment<ApplePayPaymentBundle> = {
        shippingInfo: {
          address: shippingAddress,
          email: shippingEmail!,
          phoneNumber: phoneNumber!,
          shippingMethod: cart.selectedShippingMethod!.uid
        },
        billingAddress,
        data: token
      };

      try {
        const order = await onPaymentAuthorized(expressPayment);
        // resolve the promise first so that any dependent actions have a
        // chance to run even if `completePayment` fails after for some reason.
        resolve(order);

        session.completePayment({
          status: ApplePaySession.STATUS_SUCCESS
          // TODO: use the placed order to populate the order details
          // orderDetails: {}
        });
      } catch (cause) {
        LoggerService.error(
          new Error('Payment authorization failed.', { cause })
        );

        session.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors: [new ApplePayError('unknown')]
        });
        // Do not reject the promise here to allow the customer to retry the payment.
      }
    };

    let inPaymentAuthorization = false;
    session.onpaymentauthorized = (event) => {
      const { payment } = event;
      inPaymentAuthorization = true;

      void invokeImmediately(async () => {
        try {
          // if this takes over 30 seconds, the session is automatically canceled.
          // inPaymentAuthorization is used to throw the correct interruption error type.
          await paymentAuthorizationMainHandler(payment);
        } finally {
          inPaymentAuthorization = false;
        }
      });
    };
    // #endregion

    // #region Payment Cancellation Handling
    session.oncancel = () => {
      if (inPaymentAuthorization) {
        reject(new PaymentTimeoutError('Payment timed out.'));
      } else {
        reject(new PaymentCanceledError('Payment was canceled.'));
      }
    };
    // #endregion

    // Present the payment sheet and initiate the merchant validation process.
    session.begin();

    return promise;
  }
}
