import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { PaymentInterruptedError } from './PaymentInterruptedError';

export const { PaymentAbortedError } = errorFactory(
  'PaymentAbortedError',
  PaymentInterruptedError
);

/** An error to use when a payment request is unexpectedly or forcefully aborted. */
export type PaymentAbortedError = InstanceTypeOf<typeof PaymentAbortedError>;
