import axios from 'axios';

import {
  IBrowserData,
  IOrderPaymentInfo,
  IOrderShippingInfo,
  IPlacedOrder,
  OrderShippingInfoModel,
  PlacedOrderModel
} from '@/services/models/Order';
import { AddressModel, IAddress } from '@/services/models/Address';
import { OrderPaymentInfoModel } from '@/services/models/Order/OrderPaymentInfo';
import ServerPlaceOrderService from '@/services/serverless/order/ServerPlaceOrderService';

import { DTO, Nullable } from '@/type-utils';

import type { RequiredExpressCheckoutDetailsForOrder } from '@/services/serverless/order/strategies/common/PlacePayPalOrder';
import Service from '../../Service';
import CSRFTokenService from '../CSRFTokenService';

import { CartModel } from '../../models/Cart';
import { EndlessAisleAgent } from '../EndlessAisleService';
import { EnvironmentService } from '../EnvironmentService';
import type { ApplePayPaymentBundle } from '../PaymentRequestService';

/**
 * Place order related logic.
 */
class PlaceOrderService extends Service {
  private client = axios.create({
    baseURL: '/api/order'
  });

  /**
   * Places a default order using the provided shipping and payment information.
   * @param shippingInfo - The shipping information.
   * @param billingAddress - The billing address.
   * @param paymentInfo - The payment information.
   * @param cart - The final state of the cart model.
   * @param browserData - The user's browser data at the time of place order.
   * @returns A placed order model; effectively a summary of the order.
   */
  public async placeDefaultOrder(
    shippingInfo: IOrderShippingInfo,
    billingAddress: IAddress,
    paymentInfo: Nullable<IOrderPaymentInfo>,
    cart: CartModel,
    browserData: IBrowserData
  ): Promise<PlacedOrderModel> {
    if ((typeof window === "undefined")) {
      const placedOrder = await ServerPlaceOrderService.placeDefaultOrder({
        shippingInfo,
        paymentInfo,
        billingAddress,
        cart,
        browserData
      });

      return PlacedOrderModel.from(placedOrder);
    }

    const { data } = await this.client.post<DTO<IPlacedOrder>>(
      '/place',
      {
        shippingInfo: OrderShippingInfoModel.toDTO(shippingInfo),
        paymentInfo: paymentInfo
          ? OrderPaymentInfoModel.toDTO(paymentInfo)
          : null,
        billingAddress: AddressModel.toDTO(billingAddress),
        cart: cart.toDTO(),
        browserData
      },
      {
        headers: CSRFTokenService.getHeaders()
      }
    );

    return PlacedOrderModel.from(data);
  }

  /**
   * Places an order using Apple Pay as the payment method.
   * @param shippingInfo - The shipping information.
   * @param billingAddress - The billing address.
   * @param paymentBundle - The Apple Pay payment bundle.
   * @param cart - The final state of the cart model.
   * @param browserData - The user's browser data at the time of place order.
   * @returns A placed order model; effectively a summary of the order.
   */
  public async placeApplePayOrder(
    shippingInfo: IOrderShippingInfo,
    billingAddress: IAddress,
    paymentBundle: ApplePayPaymentBundle,
    cart: CartModel,
    browserData: IBrowserData
  ): Promise<PlacedOrderModel> {
    if ((typeof window === "undefined")) {
      const placedOrder = await ServerPlaceOrderService.placeApplePayOrder({
        shippingInfo,
        billingAddress,
        paymentBundle,
        cart,
        browserData
      });

      return PlacedOrderModel.from(placedOrder);
    }

    const { data } = await this.client.post<DTO<IPlacedOrder>>(
      '/place/apple-pay',
      {
        shippingInfo: OrderShippingInfoModel.toDTO(shippingInfo),
        billingAddress: AddressModel.toDTO(billingAddress),
        paymentBundle,
        cart: cart.toDTO(),
        browserData
      },
      {
        headers: CSRFTokenService.getHeaders()
      }
    );

    return PlacedOrderModel.from(data);
  }

  /**
   * Places an order using PayPal as the payment method.
   *
   * @param expressCheckoutDetails - The details returned by the
   * [`GetExpressCheckoutDetails`](https://developer.paypal.com/api/nvp-soap/get-express-checkout-details-nvp/)
   * call after approval.
   * @param shippingInfo - The shipping information.
   * @param billingAddress - The billing address.
   * @param cart - The final state of the cart model.
   * @param browserData - The user's browser data at the time of place order.
   *
   * @returns A placed order model; effectively a summary of the order.
   */
  public async placePayPalOrder(
    expressCheckoutDetails: RequiredExpressCheckoutDetailsForOrder,
    shippingInfo: IOrderShippingInfo,
    billingAddress: IAddress,
    cart: CartModel,
    browserData: IBrowserData
  ): Promise<PlacedOrderModel> {
    if ((typeof window === "undefined")) {
      const placedOrder = await ServerPlaceOrderService.placePayPalOrder({
        expressCheckoutDetails,
        shippingInfo,
        billingAddress,
        cart,
        browserData
      });

      return PlacedOrderModel.from(placedOrder);
    }

    const { data } = await this.client.post<DTO<IPlacedOrder>>(
      '/place/paypal',
      {
        expressCheckoutDetails,
        shippingInfo: OrderShippingInfoModel.toDTO(shippingInfo),
        billingAddress: AddressModel.toDTO(billingAddress),
        cart: cart.toDTO(),
        browserData
      },
      {
        headers: CSRFTokenService.getHeaders()
      }
    );

    return PlacedOrderModel.from(data);
  }

  /**
   * Places an order using Apple Pay as the payment method.
   * @param shippingInfo - The shipping information.
   * @param billingAddress - The billing address.
   * @param encryptedCardNumber - The encrypted card number.
   * @param cart - The final state of the cart model.
   * @param browserData - The user's browser data at the time of place order.
   * @param agentType - The type of user placing the order.
   * @returns A placed order model; effectively a summary of the order.
   */
  public async placeMagtekOrder(
    shippingInfo: IOrderShippingInfo,
    billingAddress: IAddress,
    encryptedCardNumber: string,
    cart: CartModel,
    browserData: IBrowserData,
    agentType: EndlessAisleAgent
  ): Promise<PlacedOrderModel> {
    if ((typeof window === "undefined")) {
      const placedOrder = await ServerPlaceOrderService.placeMagtekOrder({
        shippingInfo,
        billingAddress,
        encryptedCardNumber,
        cart,
        browserData,
        agentType
      });

      return PlacedOrderModel.from(placedOrder);
    }

    const { data } = await this.client.post<DTO<IPlacedOrder>>(
      '/place/magtek',
      {
        shippingInfo: OrderShippingInfoModel.toDTO(shippingInfo),
        billingAddress: AddressModel.toDTO(billingAddress),
        encryptedCardNumber,
        cart: cart.toDTO(),
        browserData,
        agentType
      },
      {
        headers: CSRFTokenService.getHeaders()
      }
    );

    return PlacedOrderModel.from(data);
  }
}

// TODO: Mock this service
export default new PlaceOrderService();
