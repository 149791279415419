/**
 * Determines whether a given list of names, which form a full name, is DOMS compatible.
 *
 * The current limit for a full name in DOMS is 30 characters.
 *
 * @param names - A list of names that form a full name.
 * @returns - Whether the full name is DOMS compatible.
 * @example isFullNameDOMSCompatible(['John', 'Doe']) // true
 */
export const isFullNameDOMSCompatible = (names: Array<string>): boolean => {
  const DOMS_NAME_LENGTH_LIMIT = 30;
  return names.join(' ').length <= DOMS_NAME_LENGTH_LIMIT;
};
