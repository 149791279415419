import type {
  INotification,
  IBasicNotification,
  ICustomNotification
} from './types';

import type useNotification from './useNotification';

/**
 * Enumerates valid notification types.
 * @see {@link INotification} and {@link useNotification} to learn more about displaying notifications.
 */
enum NotificationType {
  /**
   * Simple, general use notification with predefined fields.
   * @see {@link IBasicNotification}
   */
  Basic,

  /**
   * A notification with a custom JSX body.
   * @see {@link ICustomNotification}
   */
  Custom
}

export default NotificationType;
