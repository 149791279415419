import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { InvalidStateError } from '@/utils/errors';

export const { UninitializedCartError } = errorFactory(
  'UninitializedCartError',
  InvalidStateError
);

/** Indicates that an error occurred due to an uninitialized cart. */
export type UninitializedCartError = InstanceTypeOf<
  typeof UninitializedCartError
>;
